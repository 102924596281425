import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/header-one";
import Footer from "@layout/footer/footer-one";
import SectionOne from '@containers/Tutorials/ECML2023'
import Banner from "@components/banners/ECML2023";

const Ganblr = ({ pageContext, location }) => {
	return (
		<Layout location={location}>
			<Seo title="Tutorial Proposal for ECML-PKDD 2023" />
			<Header />
			<Banner
				pageContext={pageContext}
				location={location}
				title="Revolutionizing Anomaly Detection: Approaches and Guidelines"
			/>
			<main className="site-wrapper-reveal">
				<SectionOne />
			</main>
			<Footer />
		</Layout>
	)
}

export default Ganblr
